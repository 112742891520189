import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MiddlewareService} from '@services/middleware.service';
import {AuthInterceptor} from '@services/authinterceptor.service';
import {AuthService} from '@services/auth.service';
import {EnvServiceProvider} from "@services/env.service.provider";
import {UtilsService} from "@services/utils.service";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CoolmapModule } from '@aggdirect/coolmap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';


@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatSidenavModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-M7FZPTZJ', 
    }),
    CoolmapModule.forRoot({
      analyticsRESTURL: window['__env']['RESTURLPrefix'] + 'REST/user/api/v1/',
      RESTURLPrefix: window['__env']['aggdirectAuthPrefix'],
      mapboxAccessToken: window['__env']['mapboxAccessToken'],
      mapboxStyle: window['__env']['mapboxStyle'],
      repository: 'customer'
    }),
  ],
  providers: [
    AuthService,
    MiddlewareService,
    UtilsService,
    EnvServiceProvider,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
