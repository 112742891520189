import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {EnvService} from "./env.service";
import {pluck} from 'rxjs/operators';
import {AuthCustomer, CustomerPortalResponse, Project} from '@services/auth.data-model';

@Injectable()
export class AuthService {
  public isLoggedIn: boolean;
  // FIXME: need to unsubscribe?
  public portalsLoaded$: BehaviorSubject<boolean>;
  public selectedProject: ReplaySubject<Project>;
  public customer: Subject<AuthCustomer>;
  constructor(private router: Router, private http: HttpClient, public env: EnvService) {
    this.selectedProject = new ReplaySubject<Project>();
    this.customer = new ReplaySubject<AuthCustomer>();
    this.isLoggedIn = false;
    this.portalsLoaded$ = new BehaviorSubject<boolean>(false);
  }
  loadPortals(): Observable<boolean> {
    const subject: Subject<boolean> = new Subject<boolean>();
    const url = this.env.RESTURLPrefix + 'REST/user/api/v1/user/permissions';
    const body = {app: 'web', customer_type: 0};
    const request = this.http.post(url, body).pipe(pluck('body', '1', 'data', '0', 'resp'));
    request.subscribe((response: (CustomerPortalResponse[] | string)) => {
      if (typeof response !== 'string') {
        const customer = new AuthCustomer(response[0]);
        this.customer.next(customer);
        subject.next(true);
      } else {
        // handle accounting cookie
        this.removeTokens();
        subject.next(false);
      }
    }, this.errorHandler);
    return subject.asObservable();
  }
  public logout() {
    this.http.post(this.env.userPrefix + 'REST/logout', {}).subscribe(this.errorHandler, this.errorHandler);
  }
  public getToken(): string {
    return this.parseCookie('access_token');
  }
  public parseCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  };
  public removeTokens() {
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" + `domain=` + this.env.cookieDomain;
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" + `domain=` + this.env.cookieDomain;
  }
  errorHandler = (error) => {
    this.removeTokens();
    this.router.navigate(['login']);
  };
  login(name: string, pass: string) {
    return this.http.post('REST/login',
      {
        username: name,
        password: pass
      });
  }
}
