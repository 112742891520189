import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js
  // Whether or not to enable debug mode
  public RESTURLPrefix = '';
  public aggdirectAuthPrefix = '';
  public userPrefix = '';
  public analyticsPrefix = '';
  public orderingPrefix = '';
  public truckingPrefix = '';
  public googleAnalyticsAPI = '';
  public mapboxKey = '';
  public captchaKey = '';
  public HomepagePrefix = '';
  public externalSites = [];
  public googleAPIKey = '';
  public marketplaceGAKey = '';
  public oldwebsite = '';
  public adminURL = '';
  public originURL = '';
  public cookieDomain = '';
  public platform = '';
  constructor() {}
}
