import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public inj: Injector, public router: Router, public authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone({
      headers: req.headers.set('X-Content-Type-Options', 'nosniff').
                                                                  set('Strict-Transport-Security', 'max-age=31536000;includeSubDomains' ).
                                                                  set('X-XSS-Protection', '1; mode=block'),
      withCredentials: false,
    });
    if (this.authService.getToken()) {
      const Authorization = 'Bearer ' + this.authService.getToken();
      authReq = req.clone(
        {
          headers: req.headers.set('Authorization', Authorization).set('X-Content-Type-Options', 'nosniff').
                                                                   set('Strict-Transport-Security', 'max-age=31536000;includeSubDomains' ).
                                                                   set('X-XSS-Protection', '1; mode=block'),
        }
      );
    }
    return next.handle(authReq).pipe(
      catchError((error, caught) => {
        if (error.status === 401) {
          this.authService.removeTokens();
          this.router.navigate(['/login']);
          // return Observable.throw(error);
        }
        if (error.status === 419) {
          return next.handle(authReq);
        }
        if (error.status === 419) {
          return next.handle(authReq);
        }
        // if (error.status === 419) {
        //     return authService.getRefreshToken().flatMap(t => {
        //         const authReq = req.clone({ headers: req.headers.set('authorization', t) });
        //         return next.handle(authReq);
        //     });
        // }
        //return all others errors

        // angular 10 change below, using throwError
        return throwError(error);

      }) as any
    )
  }
}
