import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { MiddlewareService } from '@services/middleware.service';

export const router: Routes = [
  {
    path: 'app',
    canActivate: [MiddlewareService],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data: {title: 'Customer'}
  },
  {
    path: '',
    loadChildren: () => import('./front/front.module').then(m => m.FrontModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(router,
    {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
