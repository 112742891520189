import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, Subject} from 'rxjs';
import {EnvService} from "./env.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MiddlewareService  {
  constructor(private auth: AuthService, private router: Router, public env: EnvService, public http: HttpClient) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const subject = new Subject<boolean>();
    this.auth.loadPortals().subscribe(portalsLoaded => {      
      subject.next(portalsLoaded);
    });
    return subject.asObservable();
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
