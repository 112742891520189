import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EnvService } from './env.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ModalTicketPreviewComponent } from '../user/reports/ticket-preview/ticket-preview.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UtilsService {
  // TODO: all constructor arguments for accounting (this commit)
  constructor(public env: EnvService, private dialog: MatDialog) {
  }

  formatphone(phone: string) {
    return '(' + phone.slice(0, 3) + ') ' + phone.slice(3, 6) + '-' + phone.slice(6, 11);
  }

  // for post
  stripPhoneNum(str) {
    return str.replace(/[()\s-]/g, '');
  }

  formatphoneIf(phone: string) {
    let formatted = '';
    if (phone) {
      if (phone.split('(').length > 1) {
        formatted = phone;
      } else {
        formatted = this.formatphone(phone);
      }
    }
    return formatted;
  }

  numbersOnly(e) {
    if (e.which < 48 || e.which > 57) {
      if (e.which !== 46) {
        e.preventDefault();
      }
    }
  }

  active_inactive_checkbox(status) {
    return status ? 'Active' : 'Inactive';
  }

  // Date and time handlers
  public toUnixTime(elem) {
    return Math.floor(elem.getTime() / 1000);
  }

  public convertDictUnix(dict) {
    for (const elem in dict) {
      if (dict[elem] instanceof Date) {
        dict[elem] = this.toUnixTime(dict[elem]);
      }
    }
  }

  colorRandomizer(a, b) {
    const RGB = [a, b, ((1 << 8) * Math.random() | 0).toString(16).padStart(2, '0')];
    const RGBstr = RGB.sort(() => 0.5 - Math.random()).join('');
    return '#' + RGBstr;
  }

  roundthis(amt) {
    return Math.round(amt * 100) / 100;
  }

  checkpasswordmatch(password: string, passwordConfirmation: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password],
        passwordConfirmationInput = group.controls[passwordConfirmation];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      }
      return passwordConfirmationInput.setErrors(null);
    };
  }

  roundCoord(amt) {
    return Math.round(amt * 10000000) / 10000000;
  }

  ifNonethenzero(val) {
    return val ? Number(val) : 0.0;
  }

  get_rand_num(number) {
    // will autogenerate for now
    return Math.floor(Math.random() * 90000) + Math.pow(10, number - 1);
  }

  getDetailOtherwiseBlank(element) {
    return element ? ', ' + element.toString() : '';
  }

  showFulladdress(element) {
    return element.street + ' ' + this.getDetailOtherwiseBlank(element.city)
      + ' ' + this.getDetailOtherwiseBlank(element.state)
      + ' ' + this.getDetailOtherwiseBlank(element.zipcode);
  }

  // TODO:temporarily for accounting
  modalCloseSuccess(dialogref, addmore) {
    setTimeout(() => {
      dialogref.close(addmore);
    }, 50);
  }

  // delete every property in object
  clearEverything(obj) {
    Object.keys(obj).forEach(function (prop) {
      delete obj[prop];
    });
  }

  // darkcolor generator
  randColor() {
    const letters = '0123456789'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      const rand = Math.round(Math.random() * 10);
      color += letters[rand !== 10 ? rand : 1];
    }
    return color;
  }

  get oldwebsite() {
    return this.env.oldwebsite;
  }

  sortingDataAccessorFix(data, sortHeaderId: string) {
    const dateHeaders = ['created', 'updated', 'start_date', 'end_date', 'quote_date', 'reg_card_expiration', 'year'];
    if (data[sortHeaderId]) {
      if (dateHeaders.includes(sortHeaderId)) {
        return new Date(data[sortHeaderId]);
      } else {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLowerCase();
        } else {
          return data[sortHeaderId];
        }
      }
    } else {
      return '';
    }
  };

  sortByProp(elem, prop, asc, date?) {
    return elem.sort((a, b) => {
      let d1 = date === undefined ? a[prop] : new Date(a[prop]);
      let d2 = date === undefined ? b[prop] : new Date(b[prop]);
      if (d1 < d2) {
        return asc ? -1 : 1;
      } else if (d1 > d2) {
        return asc ? 1 : -1;
      }
    });
  }


  downloadFile(ticketImages, canvasRef) {
    if(ticketImages && ticketImages.length > 0){
      ticketImages.forEach(task => {
        const img = new Image();
        const ticket = task;
        // if CORS issues, append date to file name
        img.src = ticket['image'] + '?' + new Date().getTime();
        // prevent CORS errors
        img.crossOrigin = 'Anonymous';
        img.addEventListener('load', () => {
          let naturalWidth = img['naturalWidth'];
          let naturalHeight = img['naturalHeight'];
          let degAngle = JSON.parse(JSON.stringify(ticket['angle']));
          let croppedWidth = (naturalWidth - (ticket['left'] + ticket['right']));
          let croppedHeight = (naturalHeight - (ticket['top'] + ticket['bottom']));
          let ratio = 0;
          let width = 0;
          // FIXME: full cropped height?
          let height = croppedHeight;
          let dx = 0;
          let dy = 0;
          let dWidth = 0;
          let dHeight = 0;
          if (!(parseInt(degAngle) % 180)) {
            ratio = (croppedWidth / croppedHeight);
            width = height * ratio;
            dWidth = width;
            dHeight = height;
          } else {
            ratio = (croppedHeight / croppedWidth);
            width = height * ratio;
            dx = ((width - height) / 2);
            dy = ((height - width) / 2);
            dWidth = height;
            dHeight = width;
          }
          let radAngle = (parseInt(ticket['angle']) * Math.PI / 180);
          // let context = this.canvasRef.nativeElement.getContext('2d');
          // use offscreen canvas to avoid mutating the canvas in view
          let context = canvasRef.nativeElement.getContext('2d');
          context.canvas.width = width;
          context.canvas.height = height;
          context.translate((width / 2), (height / 2));
          context.rotate(radAngle);
          context.translate(-(width / 2), -(height / 2));
          context.drawImage(
            img,
            ticket['left'],
            ticket['top'],
            croppedWidth,
            croppedHeight,
            dx,
            dy,
            dWidth,
            dHeight
          );
          canvasRef.nativeElement.toBlob((blob) => {
            importedSaveAs(blob, ticket['image'].substring(ticket['image'].lastIndexOf('/') + 1) + '.png')
          })
        })
      });
    }
  }


  openFileDialog(tickets) {
    // percentage of viewport height or width
    const percentage = 0.8;
    const vpRatio = window.innerWidth / window.innerHeight;
    let baseHeight;
    let baseWidth;
    if (tickets[0].ratio > vpRatio) {
      baseWidth = window.innerWidth * percentage;
      baseHeight = baseWidth / tickets[0].ratio;
    } else {
      baseHeight = window.innerHeight * percentage;
      baseWidth = baseHeight * tickets[0].ratio;
    }
    let offsets = (24 + 24 + 36 + 50);
    offsets = tickets.length > 1 ? offsets + 120 : offsets;
    const dialogRef = this.dialog.open(ModalTicketPreviewComponent, {
      height: baseHeight + 'px',
      width: baseWidth + 'px',
      disableClose: false,
      data: {
        baseHeightOffset: (baseHeight - offsets) + 'px',
        tickets: tickets
      }
    })
  }

  timeConvert(time) {
    const timeParts = time.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    // Determine if it's AM or PM
    const period = hours < 12 ? 'AM' : 'PM';
    // Convert hours to 12-hour format
    const hours12 = hours % 12 === 0 ? 12 : hours % 12;
    // Create the 12-hour formatted time string
    const time12hr = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    return time12hr;
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
}

// TODO: needed by accounting?
// @Injectable()
// export class SharedService {
//   sharingdata: any;
//
//   saveData(data) {
//     this.sharingdata = data;
//   }
//
//   getData(): any {
//     return this.sharingdata;
//   }
// }
