import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {saveAs as importedSaveAs} from "file-saver";
import {HttpClient} from "@angular/common/http";
import {EnvService} from "@services/env.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {combineLatest, Subscription} from "rxjs";
import {TicketImage, DataURL} from "../ticket-image.data";
import { Router } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ticket-preview',
  templateUrl: './ticket-preview.component.html',
  styleUrls: ['./ticket-preview.component.css']
})
export class TicketPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('imageCanvas') canvasRef: ElementRef;
  @Input() tickets: TicketImage[];
  isticket: boolean;
  public loaded: boolean = false;
  public ratio: number = 1;
  public previewHeight: number = 48;
  public dataURLs: SafeResourceUrl[] = [];
  public isError: boolean = false;
  public dataURLSub: Subscription;
  
  constructor(public dialog: MatDialog, public sanitizer: DomSanitizer, private router: Router, public utils: UtilsService) {  }
  ngOnInit() {
    this.loaded = false;
    this.tickets.forEach((ticket, i) => { ticket.loadDataURL() });
    this.dataURLSub = combineLatest(this.tickets.map(ticket => ticket.dataURL$)).subscribe((result: any[]) => {
      // condition needed?
      if (!this.loaded) {
        result.forEach((loadedData, i) => {
          if (this.tickets[i].ratio > 1) {
            this.previewHeight = (this.previewHeight / this.tickets[i].ratio);
          }
          if (loadedData.data) {
            this.dataURLs.push(this.sanitizer.bypassSecurityTrustResourceUrl(loadedData.data));
          } else { this.isError = true }
        });
        this.loaded = true;
      }
    });
  }

  ngOnDestroy() { this.dataURLSub.unsubscribe() }
}


@Component({
  styleUrls: ['./ticket-preview.component.css'],
  template: `
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="ticket-preview-button-container">
      <mat-icon class="ticket-preview-drag-handle" cdkDragHandle matTooltip="Drag Window">open_with</mat-icon>
      <button color="warn" (click)="onNoClick()" mat-mini-fab class="ticket-preview-close" >
        <mat-icon>close</mat-icon>
      </button>
      <button color="primary" mat-raised-button (click)="saveEdited()" class="ticket-preview-save">
          <mat-icon>save</mat-icon>
      </button>
    </div>
    <div [style.height]="data.baseHeightOffset" class="ticket-preview-image-container">
      <img *ngIf="dataURLs[imgIndex]"  [src]="dataURLs[imgIndex]" class="ticket-preview-image">
      <div class="ticket-spinner-container">
       <mat-spinner class="ticket-spinner" color="accent" diameter="50" *ngIf="!dataURLs[imgIndex]" ></mat-spinner>
      </div>
    </div>

    <div *ngIf="dataURLs.length > 1" class="ticket-preview-image-gallery">
      <div (click)="selectItem(i)" [style.width]="(100 / dataURLs.length) + '%'" [style.background]="i === imgIndex ? '#0000ff' : '#ffffff'"
           *ngFor="let dataURL of dataURLs; let i = index" class="ticket-preview-image-item">
        <img *ngIf="dataURL" class="ticket-preview-image-item-img" [src]="dataURL">
      </div>
    </div>
    <!--TODO: use OffscreenCanvas instead of display: none-->
    <div class='ticket-preview-hidden-canvas'><canvas #modalCanvas></canvas></div>
  </div>`
})

export class ModalTicketPreviewComponent implements OnInit {
  @ViewChild('modalCanvas', { static: true }) canvasRef: ElementRef;
  public sourceHeaders: any = {
    'Content-Type': 'application/octet-stream',
    'Access-Control-Allow-Origin': this.env.originURL,
    'Access-Control-Allow-Methods': 'GET, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Expose-Headers': 'ETag',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  };
  public dataURLs: SafeResourceUrl[] = [];
  public imgIndex = 0;
  constructor(public dialogRef: MatDialogRef<TicketPreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public http: HttpClient, public env: EnvService, public sanitizer: DomSanitizer) {

  }
  ngOnInit() {
    this.data.tickets.forEach((ticket, i) => { ticket.loadDataURL() });
    this.data.tickets.forEach(ticket => {
      ticket.dataURL$.subscribe((result: DataURL) => {
        if (result.data) {
          this.dataURLs.push(this.sanitizer.bypassSecurityTrustResourceUrl(result.data))
        } else {
          // error
        }
      })
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  selectItem(index) {
    this.imgIndex = index;
  }
  saveEdited() {
    const img = new Image();
    const ticket = this.data.tickets[this.imgIndex];
    // if CORS issues, append date to file name
    img.src = ticket['image'] + '?' + new Date().getTime();
    // caching version
    // img.src = this.ticket['image'];
    // prevent CORS errors
    img.crossOrigin = 'Anonymous';
    img.addEventListener('load', () => {
      let naturalWidth = img['naturalWidth'];
      let naturalHeight = img['naturalHeight'];
      let degAngle = JSON.parse(JSON.stringify(ticket['angle']));
      let croppedWidth = (naturalWidth - (ticket['left'] + ticket['right']));
      let croppedHeight = (naturalHeight - (ticket['top'] + ticket['bottom']));
      let ratio = 0;
      let width = 0;
      // FIXME: full cropped height?
      let height = croppedHeight;
      let dx = 0;
      let dy = 0;
      let dWidth = 0;
      let dHeight = 0;
      if (!(parseInt(degAngle) % 180)) {
        ratio = (croppedWidth / croppedHeight);
        width = height * ratio;
        dWidth = width;
        dHeight = height;
      } else {
        ratio = (croppedHeight / croppedWidth);
        width = height * ratio;
        dx = ((width - height) / 2);
        dy = ((height - width) / 2);
        dWidth = height;
        dHeight = width;
      }
      let radAngle = (parseInt(ticket['angle']) * Math.PI / 180);
      // let context = this.canvasRef.nativeElement.getContext('2d');
      // use offscreen canvas to avoid mutating the canvas in view
      let context = this.canvasRef.nativeElement.getContext('2d');
      context.canvas.width = width;
      context.canvas.height = height;
      context.translate((width / 2), (height / 2));
      context.rotate(radAngle);
      context.translate(-(width / 2), -(height / 2));
      context.drawImage(
        img,
        ticket['left'],
        ticket['top'],
        croppedWidth,
        croppedHeight,
        dx,
        dy,
        dWidth,
        dHeight
      );
      this.canvasRef.nativeElement.toBlob((blob) => {
        importedSaveAs(blob, 'edited.png')
      })
    })
  }
}



// https://stackoverflow.com/questions/50831981/how-to-use-offscreencanvas-in-typescript-project/52429874
// need to prevent Typescript errors
interface HTMLCanvasElement extends HTMLElement {
  // ...
  transferControlToOffscreen(): OffscreenCanvas;
}

interface OffscreenCanvasRenderingContext2D extends CanvasState, CanvasTransform, CanvasCompositing, CanvasImageSmoothing, CanvasFillStrokeStyles, CanvasShadowStyles, CanvasFilters, CanvasRect, CanvasDrawPath, CanvasUserInterface, CanvasText, CanvasDrawImage, CanvasImageData, CanvasPathDrawingStyles, CanvasTextDrawingStyles, CanvasPath {
  readonly canvas: OffscreenCanvas;
}
declare var OffscreenCanvasRenderingContext2D: {
  prototype: OffscreenCanvasRenderingContext2D;
  new(): OffscreenCanvasRenderingContext2D;
};
interface OffscreenCanvas extends EventTarget {
  width: number;
  height: number;
  getContext(contextId: "2d", contextAttributes?: CanvasRenderingContext2DSettings): OffscreenCanvasRenderingContext2D | null;
}
declare var OffscreenCanvas: {
  prototype: OffscreenCanvas;
  new(width: number, height: number): OffscreenCanvas;
};
